import { createSlice } from '@reduxjs/toolkit'
import { revertAll } from '../actions/appActions'

const initialState = {
 user: null,
 currentUser: {},
}

export const userSlice = createSlice({
 name: 'userState',
 initialState,
 reducers: {
  CURRENT_USER: (state, action) => {
   state.currentUser = action.payload
  },
  USER: (state, action) => {
   state.user = action.payload
  },
 },
 extraReducers: (builder) => {
  builder.addCase(revertAll, () => initialState)
 }
})

// Action creators are generated for each case reducer function
export const { CURRENT_USER, USER } = userSlice.actions

export default userSlice.reducer