import React, { useState, useCallback } from 'react';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { FcGoogle } from 'react-icons/fc'
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch } from 'react-redux'
import * as yup from 'yup';
import {
  signInWithEmailAndPassword,
  signInWithPopup,
  getRedirectResult,
  GoogleAuthProvider
} from 'firebase/auth';

import { auth, provider } from '../config/fb';
import WelAuth from './welAuth';
import { USER } from '../redux/reducers/userSlice';

export default function Login() {
  const [viewPass, setViewPass] = useState(false);
  const [errorMessage, setErrorMessage] = useState({
    pass: '',
    email: '',
  })

  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

  const schema = yup
    .object()
    .shape({
      email: yup.string().trim().required("Required").email("Invalid email").matches(emailRegex, "Invalid email"),
      password: yup.string().min(6),
    }).required();


  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(schema)
  });


  const handleCustomClaims = (user) => {
    user.getIdTokenResult().then((idTokenResult) => {
      console.log('idTokenResult.claims admin', idTokenResult.claims.admin)
      if (idTokenResult.claims?.admin) {
        navigate("/")
      } else {
        alert('You are not an administrator')
      }
    }).catch((error) => {
      console.log(error);
    });
  }


  const onSubmit = async (data, e) => {
    e.preventDefault()
    const { email, password } = data
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const user = userCredential.user;
        if (user) {
          handleCustomClaims(user)
        }
      }).catch((error) => {
        const errorCode = error.code;
        switch (errorCode) {
          case 'auth/wrong-password':
            setErrorMessage({ ...errorMessage, pass: 'incorrect password' })
            break;
          case 'auth/user-not-found':
            setErrorMessage({ ...errorMessage, email: 'email user not found' })
            break;
          default:
            break;
        }
      });
  };

  const handlePassView = useCallback(() => {
    setViewPass(!viewPass);
  }, [viewPass])

  const signInWithGoogle = async () => {
    try {
      const result = await signInWithPopup(auth, provider);
      if (result) {
        const user = result.user;
        if (user) {
          handleCustomClaims(user)
          const credential = GoogleAuthProvider.credentialFromResult(result);
          const token = credential.accessToken;
          console.log('user', user)
          console.log('Access token', token);
        }
      }
    } catch (err) {
      console.error(err);
    }
  };


  return (
    <WelAuth>
      <div className="auth-wrapper">
        <form
          onSubmit={handleSubmit(onSubmit)}
          autoComplete="on"
          className="auth-inner"
        >
          <h2>Log in</h2>
          <div>
            <button
              onClick={signInWithGoogle}
              className="loginBtn"
              type="button"
              style={{ backgroundColor: '#EA4335' }}
            >
              <p style={{ paddingRight: 10 }} > <FcGoogle size={20} /> </p>
              <h5>Continue with Google</h5>
            </button>
          </div>

          <div style={{
            paddingTop: 20,
            paddingBottom: 20,
            display: "flex",
            alignItems: "center"
          }} >
            <div style={{
              borderBottom: "2px solid lightgray",
              width: "100%"
            }} />
            <p style={{
              paddingRight: 12,
              paddingLeft: 12,
            }} > or </p>
            <div style={{
              borderBottom: "2px solid lightgray",
              width: "100%"
            }} />
          </div>

          <div className="inputContainer">
            <label>Email</label>
            <input {...register("email", { required: true })}
              id="email"
              type="email"
              autoComplete="on"
              placeholder="Your email address"
              aria-invalid={errors.mail ? "true" : "false"}
              className='inputBox'
            />
            {errors.email &&
              <p className="error">
                <AiOutlineInfoCircle size={18} className='' />
                {errors.email?.message}
              </p>}
            {errorMessage.email &&
              <p className="error">
                <AiOutlineInfoCircle size={20} className='' />
                {errorMessage.email}
              </p>}
          </div>

          <div className="inputContainer">
            <label>Password</label>
            <div className="items-center">
              <input {...register("password", { required: true })}
                id="password"
                type={viewPass ? "text" : "password"}
                autoComplete="new-password"
                placeholder="Password"
                aria-invalid={errors.password ? "true" : "false"}
                className='inputBox'
              />
            </div>

            {errors?.password &&
              <p className="error">
                <AiOutlineInfoCircle size={18} />
                {errors.password?.message}
              </p>}

            {errorMessage.pass &&
              <p className="error">
                <AiOutlineInfoCircle size={18} />
                {errorMessage.pass}
              </p>}
          </div>

          <div className="inputContainer">
            <label >
              <input
                type="checkbox"
                onChange={handlePassView}
                checked={viewPass}
              />
              show password
            </label>
          </div>

          <div className="inputContainer">
            <Link to="/resetpassword" className='link'>
              <p>Forgot password?</p>
            </Link>
          </div>

          <button
            className='loginBtn'
            type="submit"
            style={{ backgroundColor: '#4285F4' }}
          >
            Login
          </button>
        </form>
      </div>
    </WelAuth>
  )
}
