import React from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

export default function AuthFooter() {

 const { t } = useTranslation();
 const currentYear = new Date().getFullYear();

 return (
  <footer>
   <p>
    Copyright © 2022-{currentYear} Arbest S.N.C. All Rights Reserved
   </p>
  </footer>
 )
}
