import React, { useState } from 'react';
import { Outlet } from "react-router-dom";
import { styled, useTheme } from '@mui/material/styles';
import { Box, CssBaseline, Divider, IconButton, Container } from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import MainAppBar from './MainAppBar';
import SideNavBar from "../NavBar/sideNavBar";

const drawerWidth = 245;

const openedMixin = (theme) => ({
 width: drawerWidth,
 height: '100%',
 transition: theme.transitions.create('width', {
  easing: theme.transitions.easing.sharp,
  duration: theme.transitions.duration.enteringScreen,
 }),
 overflowX: 'hidden',
});

const closedMixin = (theme) => ({
 transition: theme.transitions.create('width', {
  easing: theme.transitions.easing.sharp,
  duration: theme.transitions.duration.leavingScreen,
 }),
 overflowX: 'hidden',
 width: `calc(${theme.spacing(8)} + 1px)`,
 [theme.breakpoints.up('sm')]: {
  width: `calc(${theme.spacing(8)} + 1px)`,
 },
});

const DrawerHeader = styled('div')(({ theme }) => ({
 display: 'flex',
 alignItems: 'center',
 justifyContent: 'flex-end',
 padding: theme.spacing(0, 1),
 ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
 ({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
   ...openedMixin(theme),
   '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
   ...closedMixin(theme),
   '& .MuiDrawer-paper': closedMixin(theme),
  }),
 }),
);

export default function Root() {
 const theme = useTheme();
 const [open, setOpen] = useState(true);

 const handleDrawerMenuBtn = () => {
  setOpen(!open);
 };

 const handleDrawerOpen = () => {
  setOpen(true);
 };

 const handleDrawerClose = () => {
  setOpen(false);
 };

 return (
  <React.Fragment>
   <CssBaseline />
   <Container maxWidth="lg">
    <Box sx={{ display: 'flex', height: '100%' }}>
     <MainAppBar open={open} handleDrawerOpen={handleDrawerMenuBtn} />
     <Drawer variant="permanent" open={open}>
      <DrawerHeader sx={{ backgroundColor: 'rgb(211, 211, 211);' }}>
      </DrawerHeader>
      <Divider />
      <SideNavBar collapsed={open} />
     </Drawer>
     {/* <DrawerHeader /> */}
     <main>
      <Outlet />
     </main>
    </Box>
   </Container>
  </React.Fragment>
 );
}