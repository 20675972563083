import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import Typography from '@mui/material/Typography';

import MyImageList from './MyImageList';
import { USERS_CLEAR, usersSelectors } from '../../redux/reducers/usersSlice';
import { GetPromotionalImagePost } from '../../redux/actions/users';
import { revertAll } from '../../redux/actions/appActions';

export default function GetUsersPostImage() {
 const state = useSelector(store => store.usersState)
 const usersInfo = usersSelectors.selectAll(state)

 const [promotionalPost, setPromotionalPost] = useState([])

 console.log('usersInfo', usersInfo.length)

 const dispatch = useDispatch();

 useEffect(() => {
  // dispatch(USERS_CLEAR())
  dispatch(GetPromotionalImagePost())
  if (usersInfo.length !== 0) {
   setPromotionalPost(usersInfo)
  }
  return () => dispatch(GetPromotionalImagePost())
 }, [usersInfo])

 return (
  <div className="items-center flex justify-center display-flex">
   <Typography>Get Users Post Image</Typography>
   <MyImageList data={promotionalPost} />
   <p>+</p>
  </div>
 )
}
