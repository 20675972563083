import React, { useState, useEffect } from 'react';
import {
  ImageList,
  ImageListItem,
  ImageListItemBar,
  ListSubheader,
  IconButton,
  Container
} from '@mui/material';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import { getStorage, ref, getDownloadURL, getBlob } from "firebase/storage";
import heic2any from "heic2any";

import { storage } from '../../config/fb';

export default function MyImageList(props) {
  const {
    data,
  } = props;

  const handleDownloadImage = async (imgUrl, id) => {
    const imageblob = await fetch(imgUrl)
      .then((response) => response.arrayBuffer())
      .then((buffer) => new Blob([buffer], { type: "image/jpeg" }));
    const link = document.createElement('a');
    link.href = URL.createObjectURL(imageblob);
    link.style.display = "none";
    link.download = id;
    document.body.appendChild(link);
    link.click()
    document.body.removeChild(link);
  }

  const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  return (
    <ImageList sx={{ width: '100%', height: '100%' }} cols={3 ?? 1}>
      {data.map((item) => (
        <ImageListItem key={item.id} sx={{ padding: '5px' }}>
          <img
            srcSet={`${item.downloadURL}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
            src={`${item.downloadURL}?w=164&h=164&fit=crop&auto=format`}
            alt={item.id}
            loading="lazy"
            onClick={() => openInNewTab(item.downloadURL)}
          />
          <ImageListItemBar
            sx={{ margin: '5px' }}
            title={item.user?.displayName}
            subtitle={item.caption}
            actionIcon={
              <IconButton
                sx={{ color: 'rgba(255, 255, 255)' }}
                aria-label={`info about ${'download'}`}
                onClick={() => handleDownloadImage(item.downloadURL, item.id)}
              >
                <CloudDownloadOutlinedIcon />
              </IconButton>
            }
          />
        </ImageListItem>
      ))}
    </ImageList>
  );
}