import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { revertAll } from '../actions/appActions'
import { GetPromotionalImagePost } from '../actions/users'

export const usersAdapter = createEntityAdapter({
 selectId: (users) => users.id,
 sortComparer: (a, b) => b.creation - a.creation
})

const initialState = {
 usersData: null,
 lastPostDoc: null
}

export const usersSlice = createSlice({
 name: 'usersState',
 initialState: usersAdapter.getInitialState(initialState),
 reducers: {
  // USERS_DATA: (state, action) => {
  //  state.usersData = action.payload
  // },
  USERS_DEL(state, action) {
   usersAdapter.removeOne(state, action.payload);
  },
  USERS_LAST_POST: (state, action) => {
   state.lastPostDoc = action.payload
  },
  USERS_ADDED: usersAdapter.addOne,
  USERS_UPDATE: usersAdapter.updateOne,
  USERS_ADDED_MANY(state, action) {
   const payload = action.payload
   if (payload) {
    usersAdapter.addMany(state, payload)
   }
  },
  USERS_CLEAR: usersAdapter.removeAll
 },
 extraReducers: (builder) => {
  builder.addCase(revertAll, () => usersAdapter.getInitialState(initialState))
  builder.addCase(GetPromotionalImagePost.fulfilled, (state, action) => {
   const payload = action.payload
   if (payload) {
    // console.log('fulfilled payload', payload)
    usersAdapter.addMany(state, payload)
    // usersAdapter.setAll(state, payload)
   }
  })
 }
})


export const {
 // USERS_DATA,
 USERS_DEL,
 USERS_UPDATE,
 USERS_ADDED,
 USERS_LAST_POST,
 USERS_CLEAR
} = usersSlice.actions

export const usersSelectors = usersAdapter.getSelectors((state) => state)
export default usersSlice.reducer