import { createAsyncThunk } from '@reduxjs/toolkit'
import {
 collection,
 getDocs,
 query,
 addDoc,
 where,
 orderBy,
 limit,
 startAfter
} from "firebase/firestore";
// import heic2any from "heic2any";


import { db } from '../../config/fb';
import { USERS_LAST_POST } from '../reducers/usersSlice';


const limitquery = 10

export const GetPromotionalImagePost = createAsyncThunk('users/getImage',
 async (_, { dispatch, getState }) => {
  const { lastPostDoc } = await getState().usersState

  let userRef = collection(db, "users")
  let q = query(userRef, where("lastPost", "!=", null), orderBy("lastPost", "desc"));

  // if (lastPostDoc !== null) {
  //  q = query(userRef, where("lastPost", "!=", null), orderBy("lastPost", "desc"), startAfter(lastPostDoc), limit(limitquery));
  // }

  try {
   const querySnapshot = await getDocs(q);
   // const last = querySnapshot.docs[querySnapshot.docs.length - 1];
   // dispatch(USERS_LAST_POST(last))

   if (!querySnapshot.empty) {
    let usersData = []
    const usersLastPost = querySnapshot.docs.map((doc) => {
     const docData = doc.data()
     return { ...docData }
    });

    for (let i = 0; i < usersLastPost.length; i++) {
     const snapShot = await getDocs(collection(db, "posts", usersLastPost[i].uid, "userPosts"), where("promotional", "==", true), orderBy("creation", "desc"));
     snapShot.docs.map(async (doc) => {
      let postData = []
      const data = doc.data()
      postData = data
      postData.user = usersLastPost[i]
      if (data.downloadURL !== null && data.promotional === true) {
       usersData.push(postData)
       // await fetch(data.downloadURL)
       //  .then((response) => response.blob())
       //  .then((blob) => {
       //   if (blob.type === "image/heic") {
       //    heic2any({ blob }).then((conversionResult) => {
       //     const url = URL.createObjectURL(conversionResult);
       //     postData.downloadURL = url
       //     console.log("conversionResult url", url)
       //     usersData.push(postData)
       //    })
       //   } else {
       //    usersData.push(postData)
       //   }
       //  })
       //  .catch((e) => {
       //   console.error('users blob conversion error', e)
       //  });
      }
     });
    }
    return usersData
   } else {
    return []
   }

  } catch (error) {
   console.error('getImage error: ' + error);
  }
 }
)