import React from 'react';
import { Outlet } from "react-router-dom";

import AuthFooter from '../Footer/AuthFooter';

export default function AuthRoot() {
  return (
    <div className='body' >
      <main>
        <Outlet />
      </main>
      <AuthFooter />
    </div>
  );
}
