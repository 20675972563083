import React from 'react'

import logo from '../images/LOGOfull.png';

export default function WelAuth({ children }) {
 return (
  <div className="mainContainer">
   <img className="logo" src={logo} alt="logo" />
   <div>
    <h4 className=''>Welcome Fishingfinity Admin</h4>
   </div>
   {children}
  </div>
 )
}
