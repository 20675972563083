import React, { useState, createElement, useCallback } from "react";
import { HiMenuAlt2 } from "react-icons/hi";
import { Link, useNavigate } from 'react-router-dom';
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { signOut } from "firebase/auth";
import { useSelector, useDispatch } from 'react-redux';

import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import ExposureOutlinedIcon from '@mui/icons-material/ExposureOutlined';
import PersonAddOutlinedIcon from '@mui/icons-material/PersonAddOutlined';
import PersonAddDisabledOutlinedIcon from '@mui/icons-material/PersonAddDisabledOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppOutlined';
import PermContactCalendarOutlinedIcon from '@mui/icons-material/PermContactCalendarOutlined';

import './navBar.css'
import { auth } from "../config/fb";
import { CURRENT_USER } from "../redux/reducers/userSlice";

export default function SideNavBar() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    signOut(auth).then(() => {
      navigate("/");
      console.log("Signed out successfully")
      dispatch(CURRENT_USER(null))
    }).catch((error) => {
      console.error('handleLogout', error);
    });
  }

  return (
    <Sidebar style={{ height: "100vh" }}>
      <Menu
        rootStyles={{
          textAlign: 'left',
          backgroundColor: 'rgb(211, 211, 211)',
          height: '100vh',
          fontSize: 14,
          padding: 0,
          margin: 0
        }}
      >
        <MenuItem
          component={<Link to="/" />}
          icon={<HomeOutlinedIcon fontSize="small" />} >
          Home
        </MenuItem>
        <SubMenu
          label="Add/Remove Admin"
          rootStyles={{ textAlign: 'left', fontSize: 14 }}
          icon={<ExposureOutlinedIcon fontSize="small" />}
        >
          <MenuItem
            component={<Link to="/addAminUser" />}
            icon={<PersonAddOutlinedIcon fontSize="small" />}>
            Add Admin
          </MenuItem>
          <MenuItem
            component={<Link to="/delAdminUser" />}
            icon={<PersonAddDisabledOutlinedIcon fontSize="small" />}>
            Delete Admin
          </MenuItem>
        </SubMenu>

        <SubMenu
          label="Users Data"
          rootStyles={{ textAlign: 'left', fontSize: 14 }}
          icon={<PeopleAltOutlinedIcon fontSize="small" />}
        >
          <MenuItem
            component={<Link to="/userPosts" />}
            icon={<PeopleAltOutlinedIcon fontSize="small" />}>
            Get Posts
          </MenuItem>
        </SubMenu>

        <MenuItem
          component={<Link to="/sendEmailToUsers" />}
          icon={<SendOutlinedIcon fontSize="small" />}>
          Send Email
        </MenuItem>
        <MenuItem
          component={<a onClick={handleLogout} />}
          icon={<ExitToAppOutlinedIcon
            fontSize="small" />}>
          Sign out
        </MenuItem>
      </Menu>
    </Sidebar>
  )
}
