import React, { useState, useEffect } from 'react';
import { onAuthStateChanged } from "firebase/auth";
import { useDispatch } from 'react-redux'
import { RouterProvider } from "react-router-dom";

import { MainRouter, AuthRouter } from './Routers';
import { CURRENT_USER, USER } from './redux/reducers/userSlice';
import { auth } from './config/fb';
import './App.css';
import './index.css'

function App() {
  const [currentUser, setCurrentUser] = useState(null);
  const [initializing, setInitializing] = useState(true);

  const dispatch = useDispatch();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        console.log('user', user.email);
        user.getIdTokenResult().then((idTokenResult) => {
          console.log('idTokenResult.claims admin', idTokenResult.claims.admin)
          if (idTokenResult.claims?.admin) {
            setCurrentUser(user)
            setCurrentUser(user)
            dispatch(CURRENT_USER(user))
          }
        }).catch((error) => {
          console.log(error);
        });
        if (initializing) {
          setInitializing(false);
        }
      } else {
        setCurrentUser(null)
        dispatch(USER(null))
        setInitializing(false);
      }
    })
    return () => unsubscribe()
  }, [dispatch, currentUser, initializing]);

  if (initializing) {
    return;
  }

  return (
    <RouterProvider
      router={currentUser ? MainRouter : AuthRouter}
    />
  );
}
export default App;
