import React, { useState } from 'react'
import {
 collection,
 doc,
 deleteDoc,
 getDocs,
 query,
 where,
} from "firebase/firestore";

import { db } from '../config/fb';

export default function DeleteAdmin() {

 const [emailRemove, setEmailRemove] = useState('');

 const userRef = collection(db, "users");


 const removeAdmin = async (e) => {
  e.preventDefault()
  const q = query(userRef, where("email", "==", emailRemove));
  try {
   const querySnapshot = await getDocs(q);
   console.log('querySnapshot remove admin', querySnapshot.empty);
   if (!querySnapshot.empty) {
    querySnapshot.forEach(async (res) => {
     const uid = res.id
     console.log('uid remove', uid);
     await deleteDoc(doc(db, "Admin", uid));
    })
   } else {
    alert('User does not exist');
   }
  } catch (error) {
   console.error('deleteAdmin error: ' + error);
  } finally {
   setEmailRemove('')
   alert(`you have removed ${emailRemove} from admin`)
  }
 }

 return (
  <div>
   <h1>Remove Admin</h1>
   <form
    onSubmit={removeAdmin}
    autoComplete="on"
    className="auth-inner"
   >
    <div className="items-center">
     <input
      id="email"
      type="email"
      autoComplete="auto"
      placeholder="Enter email address"
      className='inputBox'
      value={emailRemove}
      onChange={(e) => setEmailRemove(e.target.value)}
     />
    </div>

    <div>
     <button
      className='loginBtn'
      type="submit"
      style={{ backgroundColor: '#4285F4', marginTop: '20px' }}
     >
      Revome Admin
     </button>
    </div>

   </form>
  </div>
 )
}
