import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from "@reduxjs/toolkit/query";

import userReducer from './reducers/userSlice'
import usersReducer from './reducers/usersSlice'

export const store = configureStore({
  reducer: {
    userState: userReducer,
    usersState: usersReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    })
})

setupListeners(store.dispatch)